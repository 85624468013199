<template>
  <div>
    <!-- Modal -->
     <Modal id="UserChangePasswordModal" ref="UserChangePasswordModal" title="Change Password" size="md">
      <form action="" @submit.prevent="submitForm()">
              <div class="">
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center">
                    <label for="current-password" class="label"
                    >Current Password:</label
                  >
                 
                  </div>
                  
                  <InputTypeVue
                    type="password"
                    id="current-password"
                    :value="passwordList.current"
                    @input="(e) => (passwordList.current = e)"
                    placeholder="Current Password"
                  ></InputTypeVue>
                  <span
                    v-if="(isSubmit) && passwordList.current == ''"
                    class="text-danger invalid-feedback d-block mt-1 ml-2"
                    >Please enter current password.</span
                  >
                  <span
                    v-if="invalidPasswordError != ''"
                    class="text-danger invalid-feedback d-block mt-1 ml-2"
                    >The password you entered is incorrect</span
                  >
                </b-col>
                <b-col cols="12 mt-3">
                  <label for="new-password" class="label"
                    >New Password:</label
                  >
                  <InputTypeVue
                    type="password"
                    id="new-password"
                    :value="passwordList.new"
                    @input="(e) => (passwordList.new = e)"
                    placeholder="New Password"
                  ></InputTypeVue>
                  <div class="passwordInfo" v-if="(!isSubmit)&&passwordList.new == ''">
                    Your password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.</div>
                  <span
                    v-else-if="(isSubmit) && passwordList.new == ''"
                    class="text-danger invalid-feedback d-block mt-1 ml-2"
                    >New password can't be empty.</span
                  >
                  <div
                    class="d-flex align-items-center justify-content-between password-strength-bar mt-2"
                    style="height: 20px"
                  >
                    <div class="d-flex align-items-center w-100">
                      <div
                        class="bar mx-2"
                        :class="[
                          calcPassStrength == 0
                            ? 'bg-light'
                            : calcPassStrength >= 20 && calcPassStrength <= 60
                            ? 'bg-danger'
                            : calcPassStrength >= 60 && calcPassStrength <= 80
                            ? 'bg-warning'
                            : 'bg-success',
                        ]"
                        style="width: 20%; height: 10px; background: red"
                      ></div>
                      <div
                        class="bar mx-2"
                        :class="[
                          calcPassStrength < 60
                            ? 'bg-light'
                            : calcPassStrength == 60
                            ? 'bg-danger'
                            : calcPassStrength > 60 && calcPassStrength <= 80
                            ? 'bg-warning'
                            : 'bg-success',
                        ]"
                        style="width: 20%; height: 10px; background: red"
                      ></div>
                      <div
                        class="bar mx-2"
                        :class="[
                          calcPassStrength < 80
                            ? 'bg-light'
                            : calcPassStrength == 80
                            ? 'bg-warning'
                            : 'bg-success',
                        ]"
                        style="width: 20%; height: 10px; background: red"
                      ></div>
                      <div
                        class="bar mx-2"
                        :class="[
                          calcPassStrength < 100 ? 'bg-light' : 'bg-success',
                        ]"
                        style="width: 20%; height: 10px; background: red"
                      ></div>
                    </div>
                    <div
                      class="bar mx-2 float-right font-weight-bold"
                      :class="
                        calcPassStrength <= 60
                          ? 'text-danger'
                          : calcPassStrength <= 80
                          ? 'text-warning'
                          : 'text-success'
                      "
                      style="font-size: 14px"
                    >
                      <span
                        v-html="
                          calcPassStrength == 0
                            ? ''
                            : calcPassStrength <= 60
                            ? 'Weak'
                            : calcPassStrength <= 80
                            ? 'Medium'
                            : 'Strong'
                        "
                      ></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12 mt-3">
                  <label for="repeat-password" class="label"
                    >Confirm new password:</label
                  >
                  <InputTypeVue
                    type="password"
                    id="repeat-password"
                    :value="passwordList.repeat"
                    @input="(e) => (passwordList.repeat = e)"
                    placeholder="Confirm new password"
                  ></InputTypeVue>
                  <span
                    v-if="isSubmit && (passwordList.new != passwordList.repeat)"
                    class="text-danger invalid-feedback d-block mt-1 ml-2"
                    >New and Confirm password must be same.</span
                  >
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center mt-3">

                  <Button :disabled="!validateFormSubmission" :isTick="true" :isLoading="submitWithoutError">
                    Save Changes
                    </Button>
                   
                </b-col>
              </div>
            </form>
     </Modal>
    
  </div>
</template>

<script>
import axios from "axios";
import InputTypeVue from "./InputType.vue";
import Modal from "../../Layout/Modal.vue";
import Button from "../../Layout/Button.vue";
export default {
  props: {
    user: Object,
    
  },
  data() {
    return {
      
      invalidPasswordError: "",
      isSubmit: false,
      submitWithoutError:false,
      passwordList: {
        current: "",
        new: "",
        repeat: "",
      },
      error: {
        currentPassword: "",
        newPassword: "",
        repeatPassword: "",
      },
    };
  },
  watch: {
    passwordList: {
      handler() {
        // eslint-disable-next-line no-useless-escape
        const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        if (!reg.test(this.passwordList.new)) {
          this.error.email =
            "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        }
      },
      deep: true,
    },
  },
  computed: {
    calcPassStrength() {
      const conditions = [
        this.isSpecialCharacterValid,
        this.isLengthValid,
        this.isUppercaseValid,
        this.isLowercaseValid,
        this.isNumberValid,
      ];
      return conditions.filter((condition) => condition).length * 20;
    },

    isPasswordValid() {
      const passwordRegex = new RegExp(
        /^(?=^.{8,}$)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
      );
      return passwordRegex.test(this.passwordList.new) ? true : false;
    },
    isLengthValid() {
      const passwordRegex = new RegExp(/^(?=^.{8,}$).*$/);
      return passwordRegex.test(this.passwordList.new) ? true : false;
    },
    isUppercaseValid() {
      const passwordRegex = new RegExp(/^(?=.*?[A-Z]).*$/);
      return passwordRegex.test(this.passwordList.new) ? true : false;
    },
    isLowercaseValid() {
      const passwordRegex = new RegExp(/^(?=.*?[a-z]).*$/);
      return passwordRegex.test(this.passwordList.new) ? true : false;
    },
    isNumberValid() {
      const passwordRegex = new RegExp(/^(?=.*?[0-9]).*$/);
      return passwordRegex.test(this.passwordList.new) ? true : false;
    },
    isSpecialCharacterValid() {
      const passwordRegex = new RegExp(/^(?=.*?[#?!@$%^&*-]).*$/);
      return passwordRegex.test(this.passwordList.new) ? true : false;
    },
    getNewPasswordLength() {
      return this.passwordList.new != "" ? this.passwordList.new.length : 0;
    },
    validateFormSubmission() {
      return (
        this.passwordList.new == this.passwordList.repeat &&
        this.passwordList.new != "" &&
        this.passwordList.current !== ""&&this.isSpecialCharacterValid&&
        this.isNumberValid && this.isLowercaseValid && this.isUppercaseValid

      );
    },
  },
  methods: {
    reset(){
      this.passwordList = {
        current: "",
        new: "",
        repeat: "",
      }
    },
    navigateToForgetPass() {
      this.closeModal();

      this.$router.push(`/password-reset`);
    },
    async submitForm() {
      this.isSubmit = true;
      if (this.validateFormSubmission) {
        this.isSubmit = false;
        this.submitWithoutError=true;
        try {
          let data = {
            current_password: this.passwordList.current,
            new_password: this.passwordList.new,
            confirm_password: this.passwordList.repeat,
          };
          const response = await axios.post(`/user/change-password`, data);
          if (response.status == 200 && response.data.status == "success") {
            this.closeModal();
            this.$emit("UpdateAccountData");
          }
        } catch (error) {
          if (error) {
            let {errors} = error.response.data
            if(errors){

              for(let key in errors){
                if(Array.isArray(errors[key])){
                  errors[key].forEach((item)=>{
                    this.$toasted.show(item, {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
                  })
                } else{
                  this.$toasted.show(errors[key], {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
                }
              }

            } else{
              this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            }
           
          }
          throw error;
        } finally {
          this.isSubmit = false;
          this.submitWithoutError=false
        }
      }
    },
    OpenModal() {
      this.$refs.UserChangePasswordModal.open();
    },
    closeModal() {
      this.$refs.UserChangePasswordModal.close();
    },

    onValidation(e) {
      if (e.valid !== undefined) {
        this.phoneValid = !e.valid;
      } else {
        this.phoneValid = false;
      }
    },
  },
  components: {
    InputTypeVue,
    Modal,
    Button
  },
};
</script>
<style>
.duplicateProducts {
  padding: 7px 12px;
display: flex;
gap: 4px;
border-radius: 6px;
background:var(--white);
border: 1px solid var(--border-color);
color: var(--grey-button);;
font-size: 13px;
font-style: normal;
font-weight: 600;
transition: 0.3s ease all
}
.duplicateProducts:hover{
  background: var(--grey-button);;
  color:var(--white);
}
.passwordInfo{
  color: var(--grey-85);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.btn-submit {
  color: #ffffff;
  font-size: 14px;

  align-items: center;
  padding: 10px 16px;
  gap: 10px;

  width: 139px;
  height: 41px;

  background: #ffa201;
  border-radius: 8px;
}
.btn-submit:hover {
  color: #ffffff;
}
.btn-submit:active {
  background: #dd9315;
}
.change-password-description {
  width: 348px;
  font-weight: 400;
  font-size: 14px;
  color: #73738d;
  text-align: center;
}
.pointer-event-none {
  pointer-events: none;
}
.btn-submit {
  color: #ffffff;
  font-size: 14px;

  align-items: center;
  padding: 10px 16px;
  gap: 10px;

  width: 139px;
  height: 41px;

  background: #ffa201;
  border-radius: 8px;
}

.vue-tel-input:focus-within {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
#user-detail__BV_label_ {
  /* margin:0px; */
  font-size: 14px;
  color: #73738d;
}
</style>
<style scoped>
.btn-submit:hover {
  color: #ffffff;
}
.btn-submit:active {
  background: #dd9315;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.upload-image {
  text-decoration-line: underline;
  color: #ffa201;
  font-size: 14px;
  cursor: pointer;
}
.UserChangePasswordModalStyle {
  max-height: 80vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.modal-heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}
.quizell-label {
  font-size: 14px;
  font-size: 14px;
  color: #73738d;
}

.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;

  color: #18191c;
}

/* 
  .topSection{
      color:#ffffff;
       font-family: 'Poppins';
  }
  .topSection .title p{
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  }
  
   .priceSection .price{
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  }
   .priceSection .subText{
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  }
  
  .priceText span{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  }
  
  .buttonSection a{
      background:transparent;
      border: 1.02182px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
  border-radius: 25px;
  font-size:14px;
  font-weight:500;
  color:#ffffff;
  }
  .buttonSection a:hover{
      background:#ffffff;
  color:#042542;
  }
  
  .midSection{
      font-family: 'Poppins';
      color: #535355;
  }
  .midTitle{
      font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  }
  
  .midFeatures p{
      font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  }
  .midFeatures p span{
  font-weight: 600;
  }
  .midCheckFeatures p{
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  color: #535355;
  }
  
  .endSection p{
      color: #535355;
      font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  } */
</style>
